export default {
    seo: {
      title: 'OSMS 2020 Report | Bank 131',
      description: 'OSMS 2020 Report'
    },
    title: 'OSMS 2020 Report',
    content: [
         {
             label: 'Posting date 18.05.2020',
             title: 'OSMS 2020 Report.pdf',
             link: '/legal/en/special_assessment_of_working_conditions_en_2020.docx',
         }
     ]
}