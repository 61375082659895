import suot_2020 from '../../../assets/files/ru/SUOT_2020.pdf';

export default {
    seo: {
      title: 'Отчет СОУТ 2020 года | Банк 131',
      description: 'Отчет СОУТ 2020 года'
    },
    title: 'Отчет СОУТ 2020 года',
    content: [
         {
             label: 'Дата размещения 18.05.2020',
             title: 'Отчет СОУТ 2020 года.pdf',
             link: suot_2020,
         }
     ]
}